<template>
  <div class="agreement">
    <div class="agreement-item flex" v-for="(item, index) in agreementList" :key="index" @click="itemclick(item)">
      <div>{{item.agreementName}}</div>
      <van-icon name="arrow" size="0.38rem" color="#ccc"/>
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant'
import { getAgreementList, getAgreementInfoByVersion, getContractByNo } from  '@/utils/api'
import common from '@/utils/common'
import {uwStatisticAction} from "../../utils/util";
export default {
  components:{
    [Icon.name]: Icon
  },
  data() {
    return {
      agreementList:[]
    }
  },
  methods: {
    itemclick(item) {
      getContractByNo({
        electronContractNo: item.electronContractNo
      }).then(res=>{
        window.location.href = res.data.data
      })
    },
    getAgreement() {
      getAgreementList({
        uniqueNo: common.getItem('uniqueNo') // 站长编号
      }).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.agreementList = res.data.data
          }
        }
      })
      uwStatisticAction('/agreement','协议')
    }
  },
  mounted() {
    this.getAgreement()
  }
}
</script>

<style lang="less" scoped>
.agreement{
  background: #f5f5f5;
  height: 100%;
  .flex{
    display: flex;
  }
  .agreement-item{
    justify-content: space-between;
    align-items: center;
    background: #fff;
    height: 1rem;
    font-size: 15px;
    border-bottom: 1px solid #F7F8F9;
    padding: 0 0.4rem 0 0.3rem;
    &:nth-last-child(1){
      border-bottom: 0;
    }
  }
}
</style>